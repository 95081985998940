<template>
  <div class="agree-content">
    <logo-panel
      :info="agreeAdminInfo"
      class="avatar"
    />
    <div class="agree-main">
      <p class="msg">
        管理员 同意了你的举手
      </p>
      <div class="reply-btn">
        <span @click="handleReject">拒绝</span>
        <span @click="handleAgree">发言</span>
      </div>
    </div>
  </div>
</template>
<script>
import LogoPanel from '@/views/components/LogoPanel';

export default {
  name: '',
  components: {
    LogoPanel,
  },
  props: {
    agreeAdminInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {

    };
  },
  methods: {
    handleReject() {
      this.$emit('response-speak', 'reject');
    },
    handleAgree() {
      this.$emit('response-speak', 'agreed');
    },
  },
};
</script>
<style lang="scss" scoped>
.agree-content {
  position: absolute;
  left: 446px;
  bottom: 0;
  z-index: 999;
  width: 264px;
  height: 80px;
  padding: 16px;
  background: #2b6cd4;
  border-radius: 6px;
  display: flex;
}

.avatar {
  width: 48px;
  height: 48px;
  margin-right: 0;

  /deep/ {
    .user-logo-item {
      width: 100%;
      height: 100%;
    }
  }
}

.agree-main {
  width: 172px;
  margin-left: 12px;

  .msg {
    font-size: 14px;
    color: #fff;
    line-height: 20px;
    margin-bottom: 8px;
  }
}

.reply-btn {
  display: flex;
  justify-content: space-between;

  span {
    width: 80px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background: #fff;
    border-radius: 4px;
    color: #2b6cd4;
    font-size: 12px;
    cursor: pointer;
    font-weight: bold;
  }
}
</style>
